<template>
  <v-container>
    <PageTitle
      :page-title="!$language ? 'Achievements' : 'অর্জনসমূহ'"
      previous-location
    >
    </PageTitle>
    <no-content-component
      v-if="noAchievementCondition"
      :message="noAchievementMessage"
    ></no-content-component>
    <v-row
      v-for="certificate in certificates"
      :key="certificate.certificate_id"
    >
      <v-col md="10" sm="12">
        <certificate-list-item
          :certificate="certificate"
        ></certificate-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CertificateListItem from "#ef/certification/components/CertificateListItem";
import PageTitle from "@ef/global/components/PageTitle";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "MCertificate",
  components: {
    CertificateListItem,
    PageTitle,
    NoContentComponent,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    certificateRowCols() {
      return this.shareAssignmentPanel ? 8 : 9;
    },
    certificates() {
      return this.$store.getters["certificate/certificates"];
    },
    noAchievementCondition() {
      return this.certificates === undefined || this.certificates.length == 0;
    },
    noAchievementMessage() {
      return !this.$language ? "You currently have no achievements" : "বর্তমানে আপনার কোনো অর্জন নেই";
    },
  },
  created() {
    this.fetchCertificates();
  },
  methods: {
    async fetchCertificates() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        await this.$store.dispatch("certificate/list");
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          e.message,
        ]);
      }
    },
  },
};
</script>
