var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("PageTitle", {
        attrs: {
          "page-title": !_vm.$language ? "Achievements" : "অর্জনসমূহ",
          "previous-location": "",
        },
      }),
      _vm.noAchievementCondition
        ? _c("no-content-component", {
            attrs: { message: _vm.noAchievementMessage },
          })
        : _vm._e(),
      _vm._l(_vm.certificates, function (certificate) {
        return _c(
          "v-row",
          { key: certificate.certificate_id },
          [
            _c(
              "v-col",
              { attrs: { md: "10", sm: "12" } },
              [
                _c("certificate-list-item", {
                  attrs: { certificate: certificate },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }