<template>
  <m-base-card>
    <template v-slot:title>
      {{ certificate.course_title }}
    </template>
    <template v-slot:col-1>
      <m-base-card-button @click="download">{{
        !$language ? "DOWNLOAD" : "ডাউনলোড করুন"
      }}</m-base-card-button>
    </template>
    <template v-slot:col-2>
      <v-container>
        <m-base-card-info :label="!$language ? 'Description' : 'বিবরণ'">
          <!-- {{ certificate.course_description }} -->
          <span
            class="with-inner-html"
            v-html="certificate.course_description"
          ></span>
        </m-base-card-info>
      </v-container>
    </template>
    <template v-slot:col-3>
      <m-base-card-button @click="viewCertificate">{{
        !$language ? "VIEW CERTIFICATE" : "সার্টিফিকেট দেখুন"
      }}</m-base-card-button>
      <v-dialog v-model="preview">
        <file-previewer
          v-if="preview"
          :preview-url="certificate.url"
          :download-url="certificate.url"
          :title="`Certificate for ${certificate.course_title}`"
          :is-video="false"
          :is-download="false"
          @cancel="preview = false"
        ></file-previewer>
      </v-dialog>
    </template>
  </m-base-card>
</template>

<script>
import FilePreviewer from "~ef/components/FilePreviewer";
import MBaseCard from "~ef/components/MBaseCard";
import MBaseCardButton from "~ef/components/MBaseCardButton";
import MBaseCardInfo from "~ef/components/MBaseCardInfo";

export default {
  name: "CertificateListItem",
  components: { FilePreviewer, MBaseCardInfo, MBaseCardButton, MBaseCard },
  props: {
    certificate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { preview: false, url: undefined };
  },
  computed: {},
  methods: {
    async fetchURL() {
      this.fetching = true;
      try {
        await this.$store.dispatch("certificate/get", {
          courseId: this.certificate.course_id,
        });
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          e.message,
        ]);
      } finally {
        this.fetching = false;
      }
    },
    async viewCertificate() {
      if (!this.certificate.url) await this.fetchURL();
      this.preview = true;
    },
    async download() {
      try {
        if (!this.certificate.url) await this.fetchURL();
        this.certificate.url ? window.open(this.certificate.url) : null;
      } catch (e) {
        this.preview = false;
        this.$root.$emit("alert", [
          undefined,
          e.message,
        ]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.m-assignment-list-item {
  font-family: Poppins, sans-serif !important;
  color: #3d3d3d !important;

  .v-card__text {
    color: #3d3d3d !important;
  }

  button {
    font-family: Poppins, sans-serif !important;
  }
}

.with-inner-html::v-deep {
  overflow: auto;
  * {
    color: black;
  }
  table {
    border-collapse: collapse;
    th,
    td {
      border: 2px solid black;
      padding: 5px 10px;
      @media screen and (max-width: 599px) {
        padding: 2px 5px;
      }
    }
  }
}
</style>
