var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("m-base-card", {
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.certificate.course_title) + " ")]
        },
        proxy: true,
      },
      {
        key: "col-1",
        fn: function () {
          return [
            _c("m-base-card-button", { on: { click: _vm.download } }, [
              _vm._v(_vm._s(!_vm.$language ? "DOWNLOAD" : "ডাউনলোড করুন")),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "col-2",
        fn: function () {
          return [
            _c(
              "v-container",
              [
                _c(
                  "m-base-card-info",
                  {
                    attrs: { label: !_vm.$language ? "Description" : "বিবরণ" },
                  },
                  [
                    _c("span", {
                      staticClass: "with-inner-html",
                      domProps: {
                        innerHTML: _vm._s(_vm.certificate.course_description),
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "col-3",
        fn: function () {
          return [
            _c("m-base-card-button", { on: { click: _vm.viewCertificate } }, [
              _vm._v(
                _vm._s(
                  !_vm.$language ? "VIEW CERTIFICATE" : "সার্টিফিকেট দেখুন"
                )
              ),
            ]),
            _c(
              "v-dialog",
              {
                model: {
                  value: _vm.preview,
                  callback: function ($$v) {
                    _vm.preview = $$v
                  },
                  expression: "preview",
                },
              },
              [
                _vm.preview
                  ? _c("file-previewer", {
                      attrs: {
                        "preview-url": _vm.certificate.url,
                        "download-url": _vm.certificate.url,
                        title: `Certificate for ${_vm.certificate.course_title}`,
                        "is-video": false,
                        "is-download": false,
                      },
                      on: {
                        cancel: function ($event) {
                          _vm.preview = false
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }